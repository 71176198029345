import dayjs from "dayjs";
import { HistoricalOilPriceData } from "~/types/HistoricalOilPriceData";

function updateHistoricalData(
  historicalData,
  period: "fourWeeks" | "threeMonths" | "oneYear",
  timestamp: dayjs.Dayjs,
  comparisonDate: dayjs.Dayjs,
  price: string,
  formattedDate: string,
) {
  if (timestamp.isAfter(comparisonDate)) {
    const parsedPrice = parseFloat(price.replace(/,/g, "."));
    const highestPrice = parseFloat(
      historicalData.highest[period].price.replace(/,/g, "."),
    );
    const lowestPrice = parseFloat(
      historicalData.lowest[period].price.replace(/,/g, "."),
    );

    if (parsedPrice > highestPrice) {
      historicalData.highest[period] = {
        price: price,
        date: formattedDate,
      };
    }
    if (parsedPrice < lowestPrice) {
      historicalData.lowest[period] = {
        price: price,
        date: formattedDate,
      };
    }
  }
}

export function calculateHistoricalStatistics(data) {
  const fourWeeksAgo = dayjs().subtract(4, "weeks");
  const threeMonthsAgo = dayjs().subtract(3, "months");
  const oneYearAgo = dayjs().subtract(1, "year");

  const historicalData = {
    highest: {
      fourWeeks: { price: "0", date: "" },
      threeMonths: { price: "0", date: "" },
      oneYear: { price: "0", date: "" },
    },
    lowest: {
      fourWeeks: { price: "Infinity", date: "" },
      threeMonths: { price: "Infinity", date: "" },
      oneYear: { price: "Infinity", date: "" },
    },
  };

  data.forEach((item) => {
    let Timestamp: dayjs.Dayjs;
    let price: string | null = null;
    let formattedDate: string;

    if (item.changedAtUnix !== undefined && item.priceData !== undefined) {
      Timestamp = dayjs.unix(item.changedAtUnix).utc();
      price =
        item.priceData !== null
          ? item.priceData.toString().replace(/\./g, ",")
          : null;
      formattedDate = item.changedAtUtc;
    } else if (item[0] !== undefined && item[1] !== undefined) {
      Timestamp = dayjs.unix(item[0]).utc();
      price = item[1] !== null ? item[1].toString().replace(/\./g, ",") : null;
      formattedDate = Timestamp.format("DD.MM.YYYY");
    } else {
      return;
    }

    if (price !== null) {
      updateHistoricalData(
        historicalData,
        "fourWeeks",
        Timestamp,
        fourWeeksAgo,
        price,
        formattedDate,
      );
      updateHistoricalData(
        historicalData,
        "threeMonths",
        Timestamp,
        threeMonthsAgo,
        price,
        formattedDate,
      );
      updateHistoricalData(
        historicalData,
        "oneYear",
        Timestamp,
        oneYearAgo,
        price,
        formattedDate,
      );
    }
  });

  return historicalData;
}

export function getHistoricalData(
  stats: any,
  key: "highest" | "lowest",
): HistoricalOilPriceData[] {
  if (!stats || !stats[key]) return [];

  return [
    {
      timeRange: "4 Wochen",
      price: `${stats[key].fourWeeks.price} €`,
      date: `${stats[key].fourWeeks.date}`,
    },
    {
      timeRange: "3 Monate",
      price: `${stats[key].threeMonths.price} €`,
      date: `${stats[key].threeMonths.date}`,
    },
    {
      timeRange: "1 Jahr",
      price: `${stats[key].oneYear.price} €`,
      date: `${stats[key].oneYear.date}`,
    },
  ];
}
